import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './App.css';

const Verify: React.FC = () => {
  return (
    <>
      <div id="lean-link"></div>
    </>
  );
};

export default Verify;