import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function useQuery() {
     return new URLSearchParams(useLocation().search);
}

const LinkAuth: React.FC = () => {
     const [linkResp, setlinkResp] = useState("")
     let query = useQuery();
     let redirect_url = query.get("redirect_url");
     console.log(redirect_url);

     let nonce = query.get("nonce");
     let customer_id = query.get("customer_id");
     let payment_intent_id = query.get("payment_intent_id");
     let end_user_id = query.get("end_user_id");
     console.log(nonce);
     console.log(customer_id);
     console.log(payment_intent_id);
     console.log(end_user_id);

     const myCallback = (data: any) => {
          setlinkResp(data.status)
          console.log(data)
          if (data.status == "ERROR") {
               window.location.href = `${redirect_url}?link_response=${data.status}&nonce=${nonce}`;
          }
          if (data.status == "CANCELLED") {
               window.location.href = `${redirect_url}?link_response=${data.status}&nonce=${nonce}`;
          }
          if (data.status == "SUCCESS") {
               window.location.href = `${redirect_url}?link_response=${data.status}&nonce=${nonce}`;
          }
     };

     const handleScriptReady = () => {
          if ((window as any).Lean) {
               (window as any).Lean.authorize({
                    app_token: "" + process.env.REACT_APP_APP_TOKEN_2,
                    payment_intent_id: payment_intent_id,
                    customer_id: customer_id,
                    end_user_id: end_user_id,
                    sandbox: 'true',
                    callback: myCallback,
               });

          }
     };

     useEffect(() => {
          const script = document.createElement('script');
          script.src = 'https://cdn.leantech.me/link/sdk/web/latest/Lean.min.js';
          script.async = true;
          script.onload = () => {
               handleScriptReady();
          };
          document.body.appendChild(script);



          // Clean up the script when the component is unmounted
          return () => {
               document.body.removeChild(script);
          };

     }, []);



     return (
          <>
               <div id="lean-link"></div>
          </>
     );
};

export default LinkAuth;

